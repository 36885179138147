import * as React from "react"
import { Base } from "./Base"
import classNames from "classnames"
import { container } from "./Container.module.css"
import { fullWidth } from "./utils.module.css"

export const Container = ({ className, ...props }) => {
  return (
    <Base className={classNames(container, fullWidth, className)} {...props} />
  )
}
