import * as React from "react"
import classNames from "classnames"
import { inlineBlock, iconHeightSocial } from "../utils.module.css"
import { instagram, icon } from "./social.module.css"

const Icon = ({ class: className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={icon}
    viewBox="0 0 21.2 21.2"
    fill="currentColor"
    {...props}
  >
    <path d="M2.7 0h15.8a2.6 2.6 0 012.7 2.7v15.8a2.6 2.6 0 01-2.7 2.7H2.7A2.6 2.6 0 010 18.5V2.7A2.6 2.6 0 012.7 0zm12.7 2.4a1 1 0 00-1 1v2.2a1 1 0 001 1h2.4a1 1 0 001-1V3.3a1 1 0 00-1-1zM18.8 9H17a6.5 6.5 0 01.2 1.8 6.5 6.5 0 01-6.6 6.4A6.5 6.5 0 014 10.8 6.5 6.5 0 014.3 9h-2v9a.8.8 0 00.9.8H18a.8.8 0 00.8-.9V9zm-8.2-2.6a4.2 4.2 0 00-4.2 4.2 4.2 4.2 0 004.2 4 4.2 4.2 0 004.3-4 4.2 4.2 0 00-4.3-4.2z" />
  </svg>
)

export const Instagram = ({ value, className = null, ...props }) => {
  return (
    <a
      href={`https://www.instagram.com/${value}/`}
      className={classNames(
        inlineBlock,
        iconHeightSocial,
        instagram,
        className
      )}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon {...props} />
    </a>
  )
}
