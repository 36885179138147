import * as React from "react"
import { inlineBlock } from "../utils.module.css"

export const Arrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.8 17.4"
      className={inlineBlock}
      {...props}
    >
      <path
        d="M7 16V1M13 10l-6 6-6-6"
        style={{
          fill: "none",
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeWidth: "2px",
        }}
      />
    </svg>
  )
}
