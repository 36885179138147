import * as React from "react"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { Helmet } from "react-helmet"
import { contentWrapper } from "./default.module.css"
import {
  flex,
  flexColumn,
  justifyBetween,
  bgLight,
  textDark,
} from "../components/utils.module.css"
import "./default.css"
import { graphql, useStaticQuery } from "gatsby"

const IS_SERVER = typeof global.window === "undefined"

const LazyMobileNav = React.lazy(() => {
  if (!IS_SERVER && window.matchMedia("min-width: 768px").matches) {
    return Promise.resolve(() => <React.Fragment />)
  }

  return new Promise((resolve) => {
    const callback = () =>
      import("../components/MobileNav").then(({ MobileNav }) => {
        return resolve({
          default: function MobileNavWrapper(props) {
            return <MobileNav {...props} />
          },
        })
      })

    if ("requestIdleCallback" in window) {
      requestIdleCallback(callback)
    } else {
      setTimeout(callback, 0)
    }
  })
})

const SSRSuspense = ({ fallback, children }) => {
  if (IS_SERVER) {
    return fallback
  }

  return (
    <React.Suspense fallback={<React.Fragment />}>{children}</React.Suspense>
  )
}

export const Layout = ({ siteMetadata, children }) => {
  const [theme, setTheme] = React.useState("dark")
  const [isMobileNavOpen, toggleMobileNav] = React.useState(false)
  const data = useStaticQuery(graphql`
    query HeaderImage {
      allFile(filter: {name: {eq: "homepage-masthead-desktop"}}) {
        nodes {
          childImageSharp {
            fluid(maxHeight: 775, srcSetBreakpoints: [450, 615, 775]) {
              src
            }
          }
        }
      }
    }  
  `
  )

  const imageData = data.allFile.nodes[0].childImageSharp.fluid.src

  const changeTheme = React.useCallback(() => {
    setTheme(theme === "light" ? "dark" : "light")
  }, [theme])

  const themeColor = () => {
    return theme === 'dark' ? {style: {color : 'white'}} : { style: {color : 'black'} }
  }


  return (
    <div className={`${bgLight} ${textDark}`}>
      <Helmet>
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <meta name="image" property="og:image" content={imageData? imageData : ''} />
        <meta charset="UTF-8" />
      </Helmet>
      <Header
        isMobileNavOpen={isMobileNavOpen}
        onMobileNavToggle={() => toggleMobileNav(!isMobileNavOpen)}
        changeTheme={changeTheme}
        theme={theme}
        themeColor={themeColor}
      />
      <div
        className={`${flex} ${flexColumn} ${justifyBetween} ${contentWrapper}`}
        inert={isMobileNavOpen}
      >
        {children}
        <Footer siteMetadata={siteMetadata} theme={theme} />
      </div>

      <SSRSuspense fallback={<React.Fragment />}>
        <LazyMobileNav
          changeTheme={changeTheme}
          theme={theme}
          isOpen={isMobileNavOpen}
          closeNav={() => toggleMobileNav(false)}
        />
      </SSRSuspense>
    </div>
  )
}
