import * as React from "react"
import classNames from "classnames"
import { inlineBlock } from "../utils.module.css"
import { icon } from "./social.module.css"

const Icon = ({ class: className, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.3 20.3" {...props}>
    <path d="M19.2 15.2l-3.5-2.3a1.6 1.6 0 00-2.2.4c-.8 1-1.8 2.6-5.5-1S6 7.5 7 6.7a1.6 1.6 0 00.4-2.2L5 1C4.8.6 4.4 0 3.4 0A4.6 4.6 0 000 4.6c0 3 2.4 6.7 5.7 10s7 5.7 10 5.7a4.9 4.9 0 004.5-3.4c.1-.7-.5-1.4-1-1.7z" />
  </svg>
)

export const Phone = ({ value, className, ...props }) => {
  return (
    <a
      href={`tel:${value}`}
      className={classNames(inlineBlock, icon, className)}
      rel="noopener"
    >
      <Icon {...props} />
    </a>
  )
}
