import * as React from "react"
import { Logo } from "./Logo"
import { Container } from "./Container"
import { Instagram, Linkedin, Facebook } from "./Icons"
import {
  item,
  footerContainer,
  footerBody,
  iconSize,
  overflowFix,
} from "./Footer.module.css"
import {
  marginTop,
  marginLeftHalf,
  flex,
  justifyBetween,
  bgDark,
  fontBold,
  textLight,
} from "./utils.module.css"
import { graphql } from "gatsby"

export const Footer = ({ siteMetadata, theme }) => {
  const colorSvg = theme == 'dark'? 'black' : 'white';

  return (
    <footer className={marginTop}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 354">
        <path
          d="M1920 185v169H0v-51l21-13c7-5 12-5 18 0 4 4 9 6 15 6 9 0 18 3 26 6l17 5c13 4 24-1 33-8l19-17 32-31 15-8 16-6v-40c0-3 2-6-2-8l-1-3c3-3 5-8 9-10 5-3 10-7 12-12l1-6c1-4 0-5-4-5l-12-2 7-4c3-1 6-2 6-7 1-4 4-9 9-9 4 0 9 0 11 3 4 4 6 2 10 1l6-2v2l-12 15 1 1c5-2 10 0 13 4 2 3 4 4 6 0l3-1 1 3-1 2 5 1-2 4-1 4 1 17c0 5-2 7-7 4l-6-5c-1 16 6 30 7 46l5-2c6-6 14-8 22-9 7-1 12-3 17-8l4-3 49-33 4-3c2-1 5-2 6-4 10-12 23-19 37-25 5-3 11-3 16 2h7l12-9c6-5 10-5 15 0l9 11c5 5 10 8 17 7 3 0 7 1 10 3 5 3 9 4 14 0 4-3 8-3 12 1 3 3 7 2 10-1l8-6c6-4 7-4 14 1l5-7 3-6c8-7 11-6 20-1 10 6 10 9 18-4l6-5 7-3c5-4 8-9 13-11 13-6 22-15 28-27 4-8 9-14 18-16 6-1 12-4 16-10 1-4 4-3 7-1l6 2c8-3 9-3 13 4 2 3 4 5 8 3l12-2c8 1 15 5 22 10 12 8 24 15 35 25l7 1h16c6 1 12 1 17-2l10-6c6-2 12 0 14 5 1 5 5 8 9 11 12 8 25 15 36 24 9 7 17 10 28 9l32-2c4 0 7 1 10 4 1 2 4 2 6 3l17 7 49 27 12 4c9 3 18 4 25 8l17 10 13 7 7 1 15-4 3-2c2-5 6-5 9-5 4 0 8-1 11-3 6-3 9-3 14 2l7 4 25 3c6 0 12 0 16 8l7-20c1-4 4-7 9-7l1 5c2 6 6 8 13 9l14 1h5l-1-3c-1-5 0-8 5-10l12-8c3-3 6-5 11-4l6 1-1-4c-3-3-2-6 1-9l34-29 1-3 5-9c4-4 8-9 13-12 8-7 14-16 23-21 5-3 9-8 9-15l2-4 8-5c7-4 14-9 23-6h3c-6-10 4-11 9-15-5-4-6-8-3-13 5-9 12-4 19-4l1-7c-3-6-1-8 4-12l16-13h5l9 8c4 0 3-7 8-4l6 7 2 3c3 1 5 3 8 3 4 0 7 1 9 5l3 3 31 37c4 5 13 6 17 3s9-5 14-3l18 5 43 13c6 2 9 5 10 11l4 5 30 19c4 2 7 3 11 1 4-1 9-2 13 0 20 7 32 23 46 36 2 2 1 6 2 9h7l7-2c2-5 5-4 9-2 8 3 15 8 24 4h3l19 1c6 0 11 3 14 7l19 21c5 7 11 8 18 7l6-1 8-5c2-4 6-6 11-6h15zm-1705 4v49l11-5-5-2 4-33-10-9z"
          fill="currentColor"
        />
      </svg>
      <div className={`${bgDark} ${overflowFix}`}>
        <Container className={`${textLight} ${footerContainer}`}>
          <div className={`${flex} ${justifyBetween} ${footerBody}`}>
            <div className={item}>
              <p>
                <span className={fontBold}>Joda Consulting |</span> Physical
              </p>
              <p>
                Rinkhout 156
                <br />
                9240 Zele
              </p>
            </div>
            <div className={item}>
              <p>
                <span className={fontBold}>Internet |</span> Social
              </p>
              <ul className={`${flex}`}>
                <li>
                  <Instagram value="jodaconsulting" className={iconSize} />
                </li>
                <li className={marginLeftHalf}>
                  <Facebook value="jodaconsultingbv" className={iconSize} />
                </li>
                <li className={marginLeftHalf}>
                  <Linkedin
                    value="company/joda-consulting/"
                    className={iconSize}
                  />
                </li>
              </ul>
            </div>
            <div className={item}>
              <p>
                <span className={fontBold}>Contact |</span> Face-to-face
              </p>
              <p>
                <a href={`mailto:${siteMetadata.emails.contact}`}>
                  {siteMetadata.emails.contact}
                </a>
              </p>
            </div>
            <div className={item}>
              <Logo colorSvg={colorSvg}/>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export const fragments = graphql`
  fragment MetaDataQuery on Query {
    allSite {
      nodes {
        siteMetadata {
          title
          description
          emails {
            contact
          }
        }
      }
    }
  }
`
