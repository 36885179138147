import * as React from "react"
import classNames from "classnames"
import { inlineBlock, iconHeightSocial } from "../utils.module.css"
import { linkedin, icon } from "./social.module.css"

const Icon = ({ class: className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={icon}
    viewBox="0 0 24.6 22.4"
    fill="currentColor"
    {...props}
  >
    <path d="M.4 7.3h5v15.1h-5zm17.9-.5a5.6 5.6 0 00-4.9 2.6v-2h-5v15h5v-8.2c0-1.7 1-3.4 3-3.4a3.2 3.2 0 013.1 3.4v8.2h5v-8.6c0-5.9-4-7-6.2-7zM3 0a2.8 2.8 0 00-3 2.6 2.8 2.8 0 003 2.7 2.8 2.8 0 003-2.7A2.8 2.8 0 003 0z" />
  </svg>
)

export const Linkedin = ({ value, className = null, ...props }) => {
  return (
    <a
      href={`https://www.linkedin.com/in/${value}`}
      className={classNames(inlineBlock, iconHeightSocial, linkedin, className)}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon {...props} />
    </a>
  )
}
