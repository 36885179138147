import * as React from "react"

export const Mail = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.1 28.3" {...props}>
      <path
        stroke="#00ff3b"
        fill="none"
        d="M1 1l20 19L40 1M27 14l13 13M1 27l13-13"
      />
      <path stroke="#00ff3b" fill="none" d="M1 1h39v26H1z" />
    </svg>
  )
}
