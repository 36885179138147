import * as React from "react"

export const ColorToggle = ({ theme }) => {
  const transform = theme === "light" ? "25px" : "0"
  const lightOpacity = theme === "light" ? 1 : 0
  const darkOpacity = theme !== "light" ? 1 : 0

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.3 18.8" width="54">
      <path
        d="M12.3 2.1H37c4 0 7.2 3.2 7.2 7.2S41 16.5 37 16.5H12.3c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2z"
        fill="#434242"
        style={{
          transition: "opacity 300ms ease-in-out",
          opacity: darkOpacity,
        }}
      />
      <path
        d="M12.3 2.1H37c4 0 7.2 3.2 7.2 7.2S41 16.5 37 16.5H12.3c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2z"
        fill="#EFEFEF"
        style={{
          transition: "opacity 300ms ease-in-out",
          opacity: lightOpacity,
        }}
      />
      <path
        d="M9.4 18.3c-4.9 0-8.9-4-8.9-8.9S4.5.5 9.4.5s8.9 4 8.9 8.9-4 8.9-8.9 8.9z"
        style={{
          transition: "300ms ease-in-out",
          transitionProperty: "opacity, transform",
          transform: `translateX(${transform})`,
          opacity: darkOpacity,
        }}
      />
      <path
        d="M9.4 18.3c-4.9 0-8.9-4-8.9-8.9S4.5.5 9.4.5s8.9 4 8.9 8.9-4 8.9-8.9 8.9z"
        fill="#fff"
        style={{
          transition: "300ms ease-in-out",
          transitionProperty: "opacity, transform",
          transform: `translateX(${transform})`,
          opacity: lightOpacity,
        }}
      />
      <path
        d="M9.4 1C4.8 1 1 4.8 1 9.4s3.8 8.4 8.4 8.4 8.4-3.8 8.4-8.4S14 1 9.4 1m0-1a9.39 9.39 0 110 18.8A9.39 9.39 0 119.4 0z"
        fill="#00ff3b"
        style={{
          transition: "transform 300ms ease-in-out",
          transform: `translateX(${transform})`,
        }}
      />
    </svg>
  )
}
