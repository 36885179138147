import * as React from "react"
import classNames from "classnames"
import { inlineBlock } from "../utils.module.css"
import { instagram, icon } from "./social.module.css"

const Icon = ({ class: className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={icon}
    viewBox="0 0 64 64"
    fill="currentColor"
    {...props}
  >
    <path d="M58 0H6a6 6 0 00-6 6v52a6 6 0 006 6h28V38h-7V28h7v-5c0-6 2-11 11-11h8v10h-7a3 3 0 00-3 3v3h10l-2 10h-8v26h15a6 6 0 006-6V6a6 6 0 00-6-6z" />
  </svg>
)

export const Facebook = ({ value, className, ...props }) => {
  return (
    <a
      href={`https://www.facebook.com/${value}/`}
      className={classNames(inlineBlock, instagram, className)}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon {...props} />
    </a>
  )
}
