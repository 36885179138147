import * as React from "react"
import { Base } from "../Base"
import { heading, subheading } from "./index.module.css"

export const SectionHeader = ({
  title,
  subtitle,
  asTitle = "h2",
  as = "header",
  ...props
}) => {
  return (
    <Base as={as} {...props}>
      <Title as={asTitle}>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Base>
  )
}

export const Title = ({ children, as = "h2" }) => {
  return (
    <Base as={as} className={heading}>
      {children}
    </Base>
  )
}

export const SubTitle = ({ children }) => {
  return <p className={subheading}>{children}</p>
}
