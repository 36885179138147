import * as React from "react"
import { Link } from "gatsby"
import { Logo } from "./Logo"
import { Container } from "./Container"
import { HamburgerIcon } from "./Icons/Hamburger"
import { ColorToggle } from "./Icons/ColorToggle"
import {
  colorToggle,
  header,
  headerInner,
  navigationItemSpacing,
  navigationItem,
} from "./Header.module.css"
import {
  flex,
  fontBold,
  fullHeight,
  hidden,
  justifyBetween,
  mdBlock,
  mdHidden,
  relative,
} from "./utils.module.css"

export const Header = ({
  onMobileNavToggle,
  isMobileNavOpen,
  changeTheme,
  theme,
  themeColor,
}) => {
  React.useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme)
  }, [theme])

  const colorSvg = theme == 'dark' ? 'white' : 'black';

  return (
    <div className={`${header}`}>
      <Container className={fullHeight}>
        <div
          className={`${flex} ${justifyBetween} ${fullHeight} ${headerInner}`}
        >
          <Logo inert={isMobileNavOpen} colorSvg={colorSvg} />
          <HamburgerIcon
            className={mdHidden}
            as="button"
            isOpen={isMobileNavOpen}
            onClick={() => {
              onMobileNavToggle(!isMobileNavOpen)
            }}
            aria-label="Menu"
          />

          <nav
            className={`${hidden} ${mdBlock} ${relative}`}
            inert={isMobileNavOpen}
          >
            <ul className={flex}>
              <li className={navigationItem}>
                <Link to="/#projects" className={fontBold} getProps={themeColor}>
                  Projects
                </Link>
              </li>
              {
                /* <li className={`${navigationItem} ${navigationItemSpacing}`}>
                <Link to="/#clients" className={fontBold}>
                  Clients
                </Link>
              </li>
              <li className={`${navigationItem} ${navigationItemSpacing}`}>
                <Link to="/#join-the-force" className={fontBold}>
                  Join the force
                </Link>
              </li>
              <li className={`${navigationItem} ${navigationItemSpacing}`}>
                <Link to="/#blog" className={fontBold}>
                  Blog
                </Link>
              </li>*/
                <li className={`${navigationItem} ${navigationItemSpacing}`}>
                  <Link to="/#who-are-we" className={fontBold} getProps={themeColor}>
                    Who are we?
                  </Link>
                </li>
              }
              <li className={colorToggle}>
                <button onClick={changeTheme}>
                  <ColorToggle theme={theme} />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </div>
  )
}
